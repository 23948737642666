import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, GridItem, Heading, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react'
import './App.css';
import { comprehension } from './data';

function App() {

  const [language, setLanguage] = useState("English");
  const [difficulty, setDifficulty] = useState("Easy");
  const [filteredOptions, setFilteredOptions] = useState(comprehension);
  const [randomInt, setRandomInt] = useState(1);

  function getRandomInt(array) {
    return Math.floor(Math.random() * array.length);
  }

  const handleGenerate = () => {
    const filtered = comprehension.filter((item) => item.language === language && item.difficulty === difficulty);
    setFilteredOptions(filtered)
    const num = getRandomInt(filtered);
    if (num === randomInt && filtered.length > 1) {
      handleGenerate();
    } else {
      setRandomInt(num);
    }
  };

  useEffect(() => {
    handleGenerate();
    // eslint-disable-next-line
  }, []);

  return (
    <Box m={5}>
      <Grid templateColumns={['1fr', "1fr", '1fr 3fr']} gap={4}>
        <GridItem colSpan={1}>
          <Container>
            <Heading>Reading Comprehension</Heading>
            <Stack direction='row' spacing={4} my={8}>
              <Button colorScheme='teal' variant='outline' onClick={() => setLanguage("English")} isActive={language === "English"}> English </Button>
              <Button colorScheme='teal' variant='outline' onClick={() => setLanguage("Hindi")} isActive={language === "Hindi"}> Hindi </Button>
            </Stack>
            <Stack direction='row' spacing={4} my={8}>
              <Button colorScheme='teal' variant='outline' onClick={() => setDifficulty("Easy")} isActive={difficulty === "Easy"}>Easy</Button>
              <Button colorScheme='teal' variant='outline' onClick={() => setDifficulty("Medium")} isActive={difficulty === "Medium"}>Medium</Button>
              <Button colorScheme='teal' variant='outline' onClick={() => setDifficulty("Hard")} isActive={difficulty === "Hard"}>Hard</Button>
            </Stack>
            <Button colorScheme='red' onClick={() => handleGenerate()}>Generate</Button>
          </Container>
        </GridItem>

        <GridItem width={"100%"} className='section-to-print'>
          <Box mt={["30px", "30px", "0px"]} width={"100%"} >
            <Stack direction="row" justifyContent="space-between">
              <Heading>{filteredOptions[randomInt].title}</Heading>
              <Button className='no-print' onClick={() => window.print()}>Print</Button>
            </Stack>
            <Text fontSize='2xl' my={5}>{filteredOptions[randomInt].content}</Text>
            <OrderedList>
              {filteredOptions[randomInt].questions.map((question, index) => (
                <ListItem fontSize='xl' key={index}>{question}</ListItem>
              ))}
            </OrderedList>
          </Box>
        </GridItem>

      </Grid>

    </Box>
  );
}

export default App;
